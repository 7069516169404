// vue
import { ref } from 'vue'

// nuxt
import { useNuxtApp } from '#app'

// pinia
import { defineStore } from 'pinia'

// types
import type { Parent, Nullable } from '@revolutionprep/types'
import type { NitroFetchOptions } from 'nitropack'

export const useParentStore = defineStore('parent', () => {
  // fetch repositories
  const { $orbitApiFetch, $parents } = useNuxtApp()

  // state
  const parent = ref<Nullable<Parent>>(null)
  const referralSources = ref<string[]>([])

  // actions
  async function fetchReferralSources (config?: NitroFetchOptions<string>) {
    const _sources =
      await $orbitApiFetch<string[]>(
        'v2/parents/referral_sources',
        config
      )
    referralSources.value = _sources
    return _sources
  }

  async function show (
    id: number,
    config?: NitroFetchOptions<string>
  ) {
    const { parent: _parent } =
      await $parents.show<{ parent: Parent }>(id, config)
    parent.value = _parent
    return _parent
  }

  async function update (
    id: number,
    payload?: Record<string, unknown>,
    config?: NitroFetchOptions<string>
  ) {
    const { parent: _parent } =
      await $parents.update<{ parent: Parent }>(id, payload, config)
    parent.value = _parent
    return parent
  }

  return {
    parent,
    referralSources,
    fetchReferralSources,
    show,
    update
  }
})
